import {
  WarningRounded as WarningIcon,
  CheckCircleRounded as SuccessIcon,
} from '@material-ui/icons'
import React from 'react'
import Typography from 'spartacus/components/Typography'
import * as S from './style'

export interface Props {
  severity?: 'error' | 'success'
  children: React.ReactNode
}

const Alert = ({ severity = 'error', children }: Props): JSX.Element => (
  <S.Container data-testid="alert-container" severity={severity}>
    {severity === 'error' ? <WarningIcon /> : <SuccessIcon />}
    <Typography data-testid="alert-typography" kind="TS1 - Regular">
      {children}
    </Typography>
  </S.Container>
)

export default Alert
