import styled from 'styled-components'
import Colors from 'spartacus/styles/colors'
import { Props } from '.'

const getStyleFromSeverity = ({ severity }: { severity: Props['severity'] }): string => {
  if (severity === 'error') {
    return `
      background: ${Colors.Brand.Secondary5};
    `
  }

  return `
      background: ${Colors.Brand.Primary1};
    `
}

export const Container = styled.div<{ severity: Props['severity'] }>`
  padding: 12px 16px;
  border-radius: 4px;
  color: ${Colors.UI.White};
  display: flex;
  text-align: left;

  svg {
    margin-right: 16px;
  }

  span {
    color: ${Colors.UI.White};
    align-self: center;
  }

  a {
    color: ${Colors.UI.White};
    text-decoration: underline;

    &:hover {
      color: ${Colors.UI.White};
      text-decoration: none;
    }
  }

  ${getStyleFromSeverity};
`
